<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Julio 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <v-main style="padding: 0 1rem !important;">

    <!-- filtros de busqueda y botón para agregar un relación -->
    <div class="d-flex pt-3">
      <v-text-field class="me-2" outlined dense hide-details label="Documento"
        v-model="buscarNumeroDocumento">
      </v-text-field>
      
      <v-text-field class="me-2" outlined dense hide-details label="Afiliado"
        v-model="buscarNombreAfiliado">
      </v-text-field>
      
      <!-- <v-text-field class="filtros me-2" outlined dense hide-details label="Apellido del afiliado"
        v-model="buscarApellidoAfiliado">
      </v-text-field> -->

      <v-autocomplete class="me-2" ref="filtroRuta" v-model="buscarRuta" label="Ruta" outlined dense hide-details :items="tiposRuta" 
        :menu-props="menuPropsFiltroRuta" @keydown="tabulador($event, 'buscarRuta', 'filtroRuta')"
        no-data-text="Sin resultados">
      </v-autocomplete>

      <v-autocomplete ref="filtroEstado" v-model="buscarEstado" label="Estado" outlined dense hide-details :items="tiposEstado" 
        :menu-props="{offsetY: true, maxHeight: 200}" @keydown="tabulador($event, 'buscarEstado', 'filtroEstado')"
        no-data-text="Sin resultados">
      </v-autocomplete>

      <v-tooltip left color="success">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
            @mousedown.prevent="dialog = true">
            <v-icon> add </v-icon>
          </v-btn>
        </template>
        <span>Agregar</span>
      </v-tooltip>

      <v-tooltip left color="blue">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small class="ms-2" fab color="blue" v-bind="attrs" v-on="on"
            @mousedown.prevent="componenteEstado = true">
            <v-icon color="white">autorenew</v-icon>
          </v-btn>
        </template>
        <span>Cambiar estado</span>
      </v-tooltip>
    </div>

    <!-- dialogo para crear una relación -->
    <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="49.7rem">
      <v-card>
        <v-tabs v-model="opciones" background-color="blue darken-4" dark class="v-tabs--fixed-tabs">
          <v-tabs-slider color="yellow" class="v-tabs"></v-tabs-slider>
          <v-tab :disabled="leyendoArchivo" style="margin-right: 40px; margin-left: 17px !important;">Agregar relaciones uno a uno</v-tab>
          <v-tab>Agregar relaciones masivas</v-tab>
        </v-tabs>

        <!-- formulario crear una relación -->
        <v-tabs-items v-model="opciones">
          <!-- Uno a uno -->
          <v-tab-item>
            <v-card-text class="pa-5">
              <v-form ref="nuevaRelacion">

                <!-- Campo para buscar y elegir una ruta -->
                <div>
                  <v-autocomplete ref="refRutaAdd" v-model="nuevaRelacionRuta" label="Ruta" outlined dense :items="tiposRuta" 
                    :menu-props="menuPropsRuta" @keydown="tabulador($event, 'nuevaRelacionRuta', 'refRutaAdd')"
                    no-data-text="Sin resultados" :error-messages="estadoRuta">
                  </v-autocomplete>
                </div>

                <!-- Campo para buscar y elegir un afiliado -->
                <div class="d-flex">
                  <v-autocomplete ref="refTipoDocu" class="mr-2" id="tipoDocumento" v-model="filtroTipoDocumento" :items="listaDeTiposDocumento" outlined  
                    :menu-props="menuPropsTiDo" hide-details dense label="Tipo de documento" 
                    @keydown="tabulador($event, 'filtroTipoDocumento', 'refTipoDocu')" no-data-text="Sin resultados">
                  </v-autocomplete>
                  <v-text-field v-model="filtroNumeroDocumento" outlined hide-details dense label="Documento" class="mr-2">
                  </v-text-field>
                  <v-text-field v-model="filtroNombreAfiliado" outlined hide-details dense label="Afiliado">
                  </v-text-field>
                </div>

                <v-data-table dense :loading="loadingInterno" fixed-header :headers="headersInternos" :items="listaDeAfiliados"
                  :page.sync="paginaInterna" :items-per-page.sync="itemsPorPaginaInterna" :server-items-length="totalPaginasInternas"
                  v-model="afiliadoSeleccionado" class="mt-2 estiloFilas" height="32vh" :footer-props="footerPropsTabla"
                  :single-select="singleSelect" show-select>
                  <template v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="item in items" :key="item.id">
                        <td>
                          <v-checkbox v-model="afiliadoSeleccionado" :value="item"
                            @change="handleCheckboxChange(item)">
                          </v-checkbox>
                        </td>
                        <td v-for="header in headersInternos" :key="header.field">{{ item[header.field] }}</td>
                      </tr>
                      <tr v-if="calcularNoHayEscrituraInterna() && !noHayResultadoInterno">
                        <td :colspan="headersInternos.length + 1" class="mensaje-interno">
                          <span><v-icon class="icono"> feedback </v-icon></span>
                          <div>
                            <em>No hay registros, utilice los filtros para buscar un producto.</em>
                          </div>
                        </td>
                      </tr>
                      <tr v-else-if="noHayResultadoInterno">
                        <td :colspan="headersInternos.length + 1" class="mensaje-interno">
                          <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
                          <div>
                            <em style="color: #ff5252;">
                              No se encontraron resultados para la búsqueda, ingrese un nuevo valor válido.
                            </em>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-slot:footer.page-text="items">
                    {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
                  </template>
                </v-data-table>
                
                <!-- Mensaje de error si la relación entre la ruta y el afiliado existe -->
                <div v-if="datosExistentesError !== ''" class="mensaje-error">
                  <span class="error-texto">{{ datosExistentesError }}</span>
                </div>

                <!-- Botones de cerrar y guardar -->
                <div class="d-flex justify-end">
                  <v-btn color="error" text @click="close()">
                    CERRAR
                  </v-btn>
                  <v-btn depressed :disabled="relacionExistente || estadoRuta !== ''" color="success" text
                    @click="guardarRelacion()">
                    GUARDAR
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-tab-item>

          <!-- Masiva -->
          <v-tab-item>
            <v-card-text class="pa-4">
              <v-form ref="archivoSubido">
                
                <v-autocomplete v-model="rutaMasiva" label="Seleccione una ruta" class="ms-1 me-1"
                  ref="refRutaMasiva" outlined dense :items="tiposRuta" :menu-props="menuPropsRuta"
                  @keydown="tabulador($event, 'rutaMasiva', 'refRutaMasiva')"
                  no-data-text="Sin resultados" :error-messages="estadoRutaMasiva" :readonly="leyendoArchivo || tabla">
                </v-autocomplete>

                <div class="archivo ma-1" v-show="verCSV">
                  <input :disabled="leyendoArchivo || rutaMasiva === null || rutaMasiva === '' || estadoRutaMasiva !== ''" type="file" class="seleccionarArchivo" accept=".csv"
                    @change="lectorCSV($event)" ref="cargaDeRelaciones">
                  <p v-if="rutaMasiva === null || rutaMasiva === ''" style="padding-top: 0.7rem;">Primero debe seleccionar una ruta.</p>
                  <p v-else-if="validandoDocumento" style="padding-top: 0.6rem;">
                    Validando la información del archivo, espere un momento...
                    <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                      large>rotate_right
                    </v-icon>
                  </p>
                  <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
                    <v-icon class="mr-2" style="color: red;">error</v-icon>
                    Formato incorrecto, debe subir o arrastrar un archivo .csv.
                  </p>
                  <p v-else>Arrastre un archivo .CSV con las relaciones de afiliado-ruta a ingresar,
                    <br>
                    También puede hacer click en este cuadro.
                  </p>
                </div>
                <v-alert v-if="tabla" class="pa-2 ma-0" color="primary" transition="scale-transition" outlined>
                  <h4 class="d-flex justify-center"><v-icon color="primary" class="mr-2">info</v-icon>{{ mensajeCantidad }}</h4>
                </v-alert>
                <v-simple-table class="tablaRelaciones ma-1" fixed-header height="330px" v-show="tabla"
                  ref="tablaRelacionesMasivas" id="miTabla">
                  <template v-slot:default>
                    <thead class="header">
                      <tr>
                        <th> RUTA </th>
                        <th> TIPO DOCUMENTO </th>
                        <th> DOCUMENTO </th>
                        <th> VALIDACIÓN </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(registro, registroIndex) in registrosFiltrados"
                        :key="registroIndex">
                        <td>{{ registro.ruta }}</td>
                        <td>{{ registro.tipDoc }}</td>
                        <td>{{ registro.numDoc }}</td>
                        <td>
                          <div
                            v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                            <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]"
                              :key="subIndex" class="error-message">
                              {{ mensaje }}
                            </div>
                          </div>
                          <div v-else>
                            <v-icon color="green">check_circle</v-icon>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <!-- Botones de cerrar y guardar -->
                <div class="d-flex justify-end">
                  <v-btn color="error" :disabled="leyendoArchivo" text @click="close()">
                    CERRAR
                  </v-btn>
                  <v-btn v-if="mostrarBotones" color="blue" text @click="volverACargarDocumento">
                    VOLVER
                  </v-btn>
                  <v-btn v-if="mostrarBotones && hayErrores && cantidadRegistros > 0" 
                    color="success" text @click="descargarResultadoDeValidaciones()">
                    DESCARGAR
                  </v-btn>
                </div>
                <div v-if="inProgress === true" class="overlay">
                  <v-alert class="notificationValidation" persistent transition="dialog-bottom-transition">
                    <v-icon class="d-flex justify-center rotate-animation" color="white" large>rotate_right</v-icon>
                    <span class="d-flex justify-center">Guardando</span>
                  </v-alert>
                </div>
              </v-form>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>

    <!-- Tabla que muestra los registros de la relación afiliado-ruta-->
    <v-data-table :loading="loading" fixed-header :headers="headersRelaciones" :items="listaDeRelaciones"
      :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
      class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item) in items" :key="item.index">
            <td>{{ item.tipoDocumento }}. {{ item.numeroDocumento }}</td>
            <td>
              {{ item.primerNombre }} {{ item.segundoNombre }} {{ item.primerApellido }} {{ item.segundoApellido }}
            </td>
            <td>{{ item.idRuta }} - {{ item.descripcionRuta }}</td>
            <td>
              <v-icon :color="item.estadoAfru === false ? 'success' : 'error'"> 
                {{ item.estadoAfru === false ? 'check_circle' : 'cancel' }}
              </v-icon>
              {{ item.estadoAfru === false ? 'Activo' : 'Inactivo' }}
            </td>
            <td class="text-center">
              <v-tooltip bottom :color="(item.estadoRuta === true) ? 'grey' : (item.estadoAfru === false ? 'error' : 'success')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @mousedown.prevent="item.estadoRuta !== true ? abrirDialogoEstado(item) : null">
                    <v-icon :color="(item.estadoRuta === true) ? 'grey' : (item.estadoAfru === false ? 'error' : 'success')">
                      {{ item.estadoAfru === false ? 'person_add_disabled' : 'how_to_reg' }}
                    </v-icon>
                  </v-btn>
                </template>
                <span v-if="item.estadoRuta === true">Ruta inactiva</span>
                <span v-else>{{ item.estadoAfru === false ? 'Inactivar' : 'Activar' }}</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="calcularNoHayEscritura() && !noHayResultadosBusqueda">
            <td :colspan="headersRelaciones.length + 1" class="mensaje">
              <span><v-icon class="icono"> feedback </v-icon></span>
              <div>
                <em>No hay registros, utilice los filtros para buscar un afiliado relacionado con una ruta.
                </em>
              </div>
            </td>
          </tr>
          <tr v-else-if="noHayResultadosBusqueda">
            <td :colspan="headersRelaciones.length + 1" class="mensaje">
              <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
              <div>
                <em style="color: #ff5252;">No se encontraron resultados para la búsqueda, ingrese un nuevo valor.</em>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
      <template v-slot:footer.page-text="items">
        {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
      </template>
    </v-data-table>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
      <v-card>
        <v-card-title class="fondoDialog">
          <span class="text-h6">{{ mensajeEstado }}</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex justify-end" style="padding-top: 1.3rem">
            <v-btn color="error" text @click="cerrarDialogoEstado()">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para cambiar de masivamente los estados de varias relaciones afiliado ruta por medio del componente EstadoAfiliadoRuta-->
    <v-dialog v-model="componenteEstado" persistent transition="dialog-bottom-transition" max-width="49.7rem">
      <keep-alive>
      <component 
        :is="cargueArchivo.component"
        @close-estado="componenteEstado = false"
        @mostrar-validacion="mostrarValidacionEstado">
      </component>
      </keep-alive>
    </v-dialog>

    <!-- Mensaje de alerta para cuando el cambio de estado fue exitoso -->
    <template>
      <div v-if="validateProgress" class="overlay">
        <v-alert class="notificationValidationEstado ancho-estado" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeExitoso }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Mensaje de alerta para cuando la creacion por plano no genero errores de validación -->
    <template>
      <div v-if="procesoDeCreacion" class="overlay">
        <v-alert class="notificationValidationEstado ancho-creacion" persistent transition="dialog-bottom-transition">
          <v-icon class="d-flex justify-center" color="white" large>task_alt</v-icon>
          <span class="d-flex justify-center">{{ mensajeCreacion }}</span>
        </v-alert>
      </div>
    </template>

    <!-- Contenedor para mostrar los mensajes de error -->
    <div v-if="mostrarNotificacion" class="notificacion advertencia">
      <span><v-icon color="#f80505" class="rotate-animation-notification size">highlight_off</v-icon></span>
      <span>{{ mensajeNotificacion }} <br> {{ pathNotificacion }}</span>
    </div>

  </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import EstadoAfiliadoRuta from "../../../../components/EstadoAfiliadoRuta.vue";
import Papa from 'papaparse';

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {

  name: "AfiliadoRuta",
  components: {
    EstadoAfiliadoRuta,
  },

  data() {
    return {
      buscarNombreAfiliado: null,
      buscarApellidoAfiliado: null,
      buscarNumeroDocumento: null,
      buscarRuta: null,
      tiposRuta: [],
      buscarEstado: null,
      tiposEstado: [
        { text: 'Activo', value: false },
        { text: 'Inactivo', value: true },
      ],
      dialog: false,
      nuevaRelacionRuta: null,
      filtroNombreAfiliado: null,
      filtroTipoDocumento: null,
      filtroNumeroDocumento: null,
      loadingInterno: false,
      headersInternos: [
        { text: "TIPO DE DOCUMENTO", field: 'descripcion', width: "30%", align: 'left', sortable: false },
        { text: "DOCUMENTO", field: 'numeroDocumento', width: "20%", align: 'left', sortable: false },
        { text: "AFILIADO", field: 'nombreCompleto', width: "40%", align: 'left', sortable: false }
      ],
      listaDeAfiliados: [],
      paginaInterna: 1,
      totalPaginasInternas: 0,
      itemsPorPaginaInterna: 10,
      singleSelect: true,
      afiliadoSeleccionado: [],
      seleccionAnterior: [],
      noHayResultadoInterno: false,
      datosExistentesError: '',
      relacionExistente: true,
      listaDeTiposDocumento: [],
      menuPropsFiltroRuta: { offsetY: true, maxHeight: 170, maxWidth: null },
      menuPropsRuta: { offsetY: true, maxHeight: 170, maxWidth: null },
      menuPropsTiDo: { offsetY: true, maxHeight: 170, maxWidth: null },
      estadoRuta: '',
      dialogoCambiarEstado: false,
      mensajeEstado: '',
      relacionSelecionada: {},
      rutaMasiva: null,
      inProgress: false,
      leyendoArchivo: false,
      filasSeleccionadas: [],
      validandoDocumento: false,
      tabla: false,
      verCSV: true,
      registros: [],
      validaciones: [],
      formatoIncorrecto: false,
      archivoSubido: false,
      mostrarBotones: false,
      noHayResultadosBusqueda: false,
      componenteEstado: false,
      opciones: 0,
      eliminado: false,
      usuario: '',
      listaDeRelaciones: [],
      pagina: 1,
      totalPaginas: 0,
      itemsPorPagina: 10,
      loading: false,
      headersRelaciones: [
        { text: "DOCUMENTO", width: "16%", sortable: false, },
        { text: "AFILIADO", width: "26%", sortable: false, },
        { text: "RUTA", width: "26%", sortable: false, },
        { text: "ESTADO", width: "11%", sortable: false, },
        { text: "ACCIONES", align: "center", width: "5%", sortable: false, }
      ],
      footerPropsTabla: {
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Items por página:',
        'show-current-page': true,
        'show-first-last-page': true,
      },
      cargueArchivo: {
        component: 'EstadoAfiliadoRuta',
      },
      cantidadRegistrosEstado: null,
      validateProgress: false,
      mostrarNotificacion: false,
      mensajeNotificacion: "",
      pathNotificacion: "",
      estadoRutaMasiva: "",
      procesoDeCreacion: false,
      resettingForm: false,
    }
  },

  // Se inicializa la variable userRoles con los roles del usuario autenticado
  created() {
    this.rutas();
    this.tiposDeDocumentos();
  },

  // Se mapean los estados del store a propiedades computadas
  computed: {
    ...mapState(["auth", "enterprise"]),
    /** Retorna un mensaje con la cantidad de relaciones a las cuales se les cambio el estado */
    mensajeExitoso() {
      const cantidad = this.cantidadRegistrosEstado > 1 ? 'registros' : 'registro';
      const mensaje = `Se han cargado ${this.cantidadRegistrosEstado} ${cantidad} correctamente. La actualización estará disponible en aproximadamente 10 minutos.`;
      return mensaje;
    },
    /** Retorna un mensaje con la cantidad de relaciones creadas por plano */
    mensajeCreacion() {
      const cantidad = this.cantidadRegistros > 1 ? 'relaciones' : 'relación';
      const mensaje = `Creación exitosa para ${this.cantidadRegistros} ${cantidad}.`;
      return mensaje;
    },
    /**
     * Devuelve la cantidad de registros que tienen mensajes de validación.
     * @returns {number} - La cantidad de registros con mensajes de validación.
     */
    registrosConError() {
      const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
      return registrosConError.length;
    },

    /**
     * Filtra y devuelve los registros que tienen mensajes de validación.
     * Si no hay registros con mensajes de validación, devuelve todos los registros.
     * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
     */
    registrosFiltrados() {
      if (this.registrosConError > 0) {
        const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
        return registrosFiltrados;
      }
      return this.registros;
    },
    /**
     * Filtra y devuelve las validaciones que tienen mensajes de error.
     * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
     * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
     */
    validacionesFiltradas() {
      if (this.registrosConError > 0) {
        return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
      }
      return this.validaciones;
    },
    /**
     * Verifica si existen errores en las validaciones.
     * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
     */
    hayErrores() {
      return this.validaciones.some(validacion => validacion.length > 0);
    },
    /** Cuenta los registros con errores */
    cantidadRegistrosConError() {
      return this.registros.filter((registro, index) => this.validaciones[index].length > 0).length;
    },
    /** Establece un valor en un mensaje para detallar la cantidad de registros procesados y si tienen error */
    mensajeCantidad() {
      const validoText = this.cantidadRegistros === 1 ? 'Se validó' : 'Se validaron';
      const registrosText = this.cantidadRegistros === 1 ? 'registro' : 'registros';
      let mensaje = `${validoText} ${this.cantidadRegistros} ${registrosText}`;
      if (this.cantidadRegistrosConError > 0) {
        const erroresText = this.cantidadRegistrosConError === 1 ? 'presenta el siguiente error' : 'presentan los siguientes errores';
        mensaje += ` y ${this.cantidadRegistrosConError} ${erroresText}.`;
      } else {
        mensaje += '.';
      }
      return mensaje;
    },
  },

  // Se vigila el cambio en las variables y se ejecuta listarRelaciones()
  watch: {
    pagina: function () {
      this.listarRelaciones();
    },
    itemsPorPagina: function () {
      this.pagina = 1;
      this.listarRelaciones();
    },
    'buscarNombreAfiliado': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 300),
    'buscarApellidoAfiliado': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 300),
    'buscarNumeroDocumento': debounce(function () {
      this.pagina = 1;
      this.listarRelaciones();
    }, 300),
    'buscarRuta': function () {
      this.pagina = 1;
      this.listarRelaciones();
    },
    'buscarEstado': function () {
      this.pagina = 1;
      this.listarRelaciones();
    },
    'dialog': function(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.$nextTick(() => {
            if (this.$refs.refTipoDocu) {
              this.menuPropsTiDo.maxWidth = this.$refs.refTipoDocu.$el.offsetWidth;
            }
            if (this.$refs.refRutaAdd) {
              this.menuPropsRuta.maxWidth = this.$refs.refRutaAdd.$el.offsetWidth;
            }
          })
        }, 100)
      }
    },
    paginaInterna: function () {
      this.listarAfiliados();
    },
    itemsPorPaginaInterna: function () {
      this.paginaInterna = 1;
      this.listarAfiliados();
    },
    'filtroNombreAfiliado': debounce(function () {
      this.afiliadoSeleccionado = [];
      this.paginaInterna = 1;
      this.listarAfiliados();
    }, 300),
    'filtroTipoDocumento': debounce(function () {
      const nombre = this.filtroNombreAfiliado === null || this.filtroNombreAfiliado === '' ? false : true;
      if (nombre) {
        this.afiliadoSeleccionado = [];
        this.paginaInterna = 1;
        this.listarAfiliados();
      }
    }, 300),
    'filtroNumeroDocumento': debounce(function () {
      this.afiliadoSeleccionado = [];
      this.paginaInterna = 1;
      this.listarAfiliados();
    }, 300),
    'nuevaRelacionRuta': debounce(function () {
      this.validarExistencia();
    }, 100),
    'afiliadoSeleccionado': debounce(function () {
      this.validarExistencia();
    }, 100),
    'validateProgress': function(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.validateProgress = false;
          this.listarRelaciones();
        }, 5000);
      }
    },
    'procesoDeCreacion': function(newValue) {
      if (newValue === true) {
        setTimeout(() => {
          this.procesoDeCreacion = false;
          this.listarRelaciones();
        }, 3500);
      }
    },
    'rutaMasiva': function () {
      const idRuta = (this.rutaMasiva === null || this.rutaMasiva === '') ? null : this.rutaMasiva;
      if (idRuta !== null) {
        const estadoRuta = this.tiposRuta.filter(item => item.value === idRuta)?.estado;
        this.estadoRutaMasiva = estadoRuta ? 'La ruta está inactiva.' : ''; 
      } else {
        this.estadoRutaMasiva = '';
      }
    },
    'componenteEstado': function(newValue) {
      if (newValue === false) {
        setTimeout(() => {
          this.listarRelaciones();
        }, 100);
      }
    },
  },

  // Se asigna el valor del username del usuario autenticado a usuario en mayúsculas
  mounted() {
    this.usuario = this.auth.username.toUpperCase();
  },

  methods: {
    /**
     * Consulta las rutas y las establece en el array tiposRuta,
     * También define el tamaño del campo por medio de las propiedades del refs.
     */
    rutas() {
      if (this.enterprise.code === 4) {
        this.tiposRuta = [];
        this.$http.get(`msa-administration/api/ruta/list`)
        .then((response) => {
          this.tiposRuta = response.data.map(item => ({
            text: `${item.idRuta} - ${item.descripcionRuta}`,
            value: item.idRuta,
            estado: item.eliminado,
          }))
        }).catch((error) => {
          this.verError(error);
        })
      }
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs.filtroRuta) {
            this.menuPropsFiltroRuta.maxWidth = this.$refs.filtroRuta.$el.offsetWidth;
          }
        })
      }, 100)
    },
    /**
     * Obtiene la lista de relaciones mediante una solicitud HTTP GET al servidor.
     * Las relaciones se obtienen con paginación y se filtran según los parámetros especificados.
     * Se actualiza el número total de páginas.
     * Además, se controla el estado de carga durante la solicitud.
     */
    listarRelaciones() {
      this.loading = true;
      let ruta = this.buscarRuta === null ? '' : this.buscarRuta;
      let estado = this.buscarEstado === null ? '' : this.buscarEstado;
      let nombre = this.buscarNombreAfiliado === null ? '' : this.buscarNombreAfiliado;
      let apellido = this.buscarApellidoAfiliado === null ? '' : this.buscarApellidoAfiliado;
      let numero = this.buscarNumeroDocumento === null ? '' : this.buscarNumeroDocumento;
      if (ruta === '' && estado === '' && nombre === '' && apellido === '' && numero === '') {
        this.listaDeRelaciones = [];
        this.loading = false;
        this.totalPaginas = 0;
        this.noHayResultadosBusqueda = false;
        return;
      }
      this.$http.get(`msa-administration/api/afiliadoRuta/list`, {
        params: {
          page: this.pagina - 1,
          size: this.itemsPorPagina,
          idEmpresa: this.enterprise.code,
          nombre: `${nombre.toLowerCase()}`,
          apellido: `${apellido.toLowerCase()}`,
          numeroDocumento: `${numero.toLowerCase()}`,
          ruta: `${ruta}`,
          estado: `${estado}`,
        },
      }).then(response => {
        this.listaDeRelaciones = response.data.content;
        // Actualizar el número total de páginas
        this.totalPaginas = response.data.totalElements;
        if (response.data.totalElements === 0) {
          this.noHayResultadosBusqueda = true;
        } else {
          this.noHayResultadosBusqueda = false;
        }
        this.loading = false;
      }).catch(error => {
        this.verError(error);
        this.loading = false;
      })
    },
    /**
     * Recupera una lista de tipos de documentos desde el servidor y la almacena en la propiedad
     * 'listaDeTiposDocumento' del componente.
     * @throws {Error} Si ocurre un error durante la solicitud al servidor.
     */
    tiposDeDocumentos() {
      this.listaDeTiposDocumento = [];
      this.$http.get(`msa-administration/tipoDocumento/listar`)
      .then((response) => {
        this.listaDeTiposDocumento = response.data.map(item => ({
          text: `${item.descripcion}`,
          value: item.tipoDocumento,
          tipoDocumento: item.tipoDocumento,
        }))
      }).catch((error) => {
        this.verError(error);
      })
    },
    /**
     * Permite la busqueda de un afiliado para relacionarlo a una ruta.
     * Establece los resultados de la busqueda en el array listaDeAfiliados
     * y los muestra de manera paginada.
     */
    listarAfiliados() {
      const afiliadoSelecionado = this.seleccionAnterior.slice();
      this.loadingInterno = true;
      let nombre = this.filtroNombreAfiliado === null ? '' : this.filtroNombreAfiliado;
      let tipoDocumento = this.filtroTipoDocumento === null ? '' : this.filtroTipoDocumento;
      let numero = this.filtroNumeroDocumento === null ? '' : this.filtroNumeroDocumento;
      if (nombre === '' && tipoDocumento === '' && numero === '') {
        this.listaDeAfiliados = [];
        this.loadingInterno = false;
        this.totalPaginasInternas = 0;
        this.noHayResultadoInterno = false;
        return;
      }
      this.$http.get(`msa-administration/api/afiliado/page`, {
        params: {
          page: this.paginaInterna - 1,
          size: this.itemsPorPaginaInterna,
          idEmpresa: this.enterprise.code,
          nombre: `${nombre.toLowerCase()}`,
          tipoDocumento: `${tipoDocumento.toUpperCase()}`,
          numeroDocumento: `${numero.toLowerCase()}`,
        },
      }).then(response => {
        this.listaDeAfiliados = response.data.content.map(afiliado => ({
          ...afiliado,
          nombreCompleto: `${afiliado.primerNombre} ${afiliado.segundoNombre || ''} ${afiliado.primerApellido} ${afiliado.segundoApellido || ''}`.trim(),
        }));
        this.totalPaginasInternas = response.data.totalElements;
        if (response.data.totalElements === 0) {
          this.noHayResultadoInterno = true;
        } else {
          this.noHayResultadoInterno = false;
        }
        this.afiliadoSeleccionado = afiliadoSelecionado.filter(afiliado => {
          return this.listaDeAfiliados.some(item => item.idAfiliado === afiliado.idAfiliado);
        })
        this.loadingInterno = false;
      }).catch(error => {
        this.verError(error);
        this.loadingInterno = false;
      })
    },
    /**
     * Maneja el cambio de estado del checkbox.
     * Actualiza los registros seleccionados y realiza acciones en función del estado del checkbox.
     * @param item - Registro seleccionado.
     */
    handleCheckboxChange(item) {
      if (this.resettingForm) return;
      
      if (item) {
        this.afiliadoSeleccionado = [item];
      } else {
        this.afiliadoSeleccionado = [];
        this.valorSeleccionado = null;
      }
      this.seleccionAnterior = this.afiliadoSeleccionado.slice();
    },
    /**
      * Calcula si no hay escritura en los campos de búsqueda.
      * @returns {boolean} - Valor que indica si no hay escritura en los campos de búsqueda.
      */
    calcularNoHayEscrituraInterna() {
      const nombre = (this.filtroNombreAfiliado === null || this.filtroNombreAfiliado === '') ? true : false;
      const apellido = (this.filtroTipoDocumento === null || this.filtroTipoDocumento === '') ? true : false;
      const documento = (this.filtroNumeroDocumento == null || this.filtroNumeroDocumento === '') ? true : false;
      return nombre && apellido && documento;
    },
    /**
     * Se encarga de realizar una validación para verificar si existe una relación entre el id del afiliado 
     * y el código de ruta seleccionados en la tabla afiliado_ruta de postgres.
     */
    validarExistencia() {
      const idAfiliado = this.afiliadoSeleccionado.length === 1 ? this.afiliadoSeleccionado[0].idAfiliado : null;
      // const estadoAfiliado = this.afiliadoSeleccionado.length === 1 ? this.afiliadoSeleccionado[0].estadoAfiliado : null;
      const idRuta = (this.nuevaRelacionRuta === null || this.nuevaRelacionRuta === '') ? null : this.nuevaRelacionRuta;
    
      if (idRuta !== null) {
        const estadoRuta = this.tiposRuta.filter(item => item.value === idRuta)?.estado;
        this.estadoRuta = estadoRuta ? 'La ruta está inactiva.' : '';
      } else {
        this.estadoRuta = '';
      }

      // if (estadoAfiliado !== null && estadoAfiliado === true) {
      //   this.datosExistentesError = "El afiliado esta inactivo.";
      //   this.relacionExistente = true;
      //   return;
      // }

      if (idAfiliado !== null && idRuta !== null) {
        
        this.$http.get("msa-administration/api/afiliadoRuta/existencia", {
          params: {
            idEmpresa: this.enterprise.code,
            idAfiliado: idAfiliado,
            idRuta: idRuta
          }
        }).then((response) => {
          if (response.data !== "") {
            this.datosExistentesError = response.data;
            this.relacionExistente = true;
          } else {
            this.datosExistentesError = "";
            this.relacionExistente = false; 
          }
        }).catch((error) => {
          this.verError(error);
          this.datosExistentesError = "Error en la validación de datos";
          this.relacionExistente = true;
        });
      } else {
        this.datosExistentesError = "";
        this.relacionExistente = true;
      }
    },
    /**
     * Maneja el evento de presionar la tecla Tab para campos v-autocomplete.
     * Si se presiona la tecla Tab y el valor actual del modelo es null,
     * desenfoca el campo y establece el valor del modelo como una cadena vacía.
     *
     * @param {KeyboardEvent} event - El objeto del evento de teclado.
     * @param {string} model - El nombre de la propiedad del modelo.
     * @param {string} ref - El nombre de la referencia del campo v-autocomplete.
     */
    tabulador(event, model, ref) {
      if (event.key === 'Tab' && this[model] === null) {
        this.$refs[ref].blur();
        this[model] = '';
      }
    },
    /**
     * Calcula si no hay escritura en los campos de búsqueda
     * @returns {boolean} - Indicador de no hay escritura
     */
    calcularNoHayEscritura() {
      const nombre = (this.buscarNombreAfiliado === null || this.buscarNombreAfiliado === '') ? true : false;
      const documento = (this.buscarNumeroDocumento === null || this.buscarNumeroDocumento === '') ? true : false;
      const ruta = (this.buscarRuta == null || this.buscarRuta === '') ? true : false;
      const estado = (this.buscarEstado == null || this.buscarEstado === '') ? true : false;
      return nombre && documento && ruta && estado;
    },
    /**
     * Guarda la información de una nueva relación en el servidor.
     * Crea un objeto con los datos de la relación, incluyendo la fecha de creación actual,
     * y realiza una solicitud al servidor para ingresar la relación. Luego, actualiza la lista
     * de las relaciones y restablece el formulario.
     */
    guardarRelacion() {
      const horaActual = new Date;
      // Realiza la petición al backend para guardar la relación
      const relacionarRuta = {
        idAfRu: null,
        afiliado: {
          idAfiliado: this.afiliadoSeleccionado[0].idAfiliado,
        },
        ruta: {
          idRuta: this.nuevaRelacionRuta,
        },
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        fecha: horaActual.toISOString(),
        eliminado: this.eliminado,
        usuario: this.usuario,
      };
      // Realizar la petición POST al controlador para guardar la relación
      this.$http
        .post(`msa-administration/api/afiliadoRuta/save`, relacionarRuta)
        .then(() => {
          this.listarRelaciones();
          this.close();
        })
        .catch(error => {
          this.verError(error);
        });
    },
    /**
     * Abre el diálogo para cambiar el estado de una relación.
     * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
     * Guarda la relación seleccionada en la propiedad 'relacionSelecionada'.
     * Este método se invoca cuando se desea cambiar el estado de una relación.
     * @param {object} item - El relación seleccionada.
     */
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.relacionSelecionada = item;
      this.mensajeEstado = this.relacionSelecionada.estadoAfru === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
      setTimeout(() => {
        this.mensajeEstado = this.relacionSelecionada.estadoAfru === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
      }, 4000);
    },
    /**
     * Cambia el estado de una relación.
     * Actualiza el estado de la relación seleccionada ('relacionSelecionada') según la lógica definida.
     * Realiza una solicitud HTTP PUT al servidor para actualizar el estado de la relación.
     * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de relaciones.
     */
    cambiarEstado() {
      this.relacionSelecionada.estadoAfru = this.relacionSelecionada.estadoAfru == false ? true : false;
      const horaActual = new Date;
      const cambiarEstado = {
        idAfru: this.relacionSelecionada.idAfru,
        afiliado: {
          idAfiliado: this.relacionSelecionada.idAfiliado,
          eliminado: this.relacionSelecionada.estadoAfiliado,
          estadoAfiliadoValue: this.relacionSelecionada.estadoAfiliadoValue,
        },
        empresa: {
          idEmpresa: this.enterprise.code,
        },
        eliminado: this.relacionSelecionada.estadoAfru,
        lastmodifieddate: horaActual.toISOString(),
        lastmodifiedby: this.usuario,
      };
      this.$http
        .put(`msa-administration/api/afiliadoRuta/update`, cambiarEstado)
        .then(() => {
          this.cerrarDialogoEstado();
          this.listarRelaciones();
        }).catch(error => {
          this.verError(error);
        })
    },
    /**
     * Restablece los valores usados para el cambio de estado
     * y cierra el dialogo.
     */
    cerrarDialogoEstado() {
      this.relacionSelecionada = {};
      this.dialogoCambiarEstado = false;
    },
    /**
     * Método para leer la información de un archivo CSV, realizar validaciones en el backend
     * y mostrar los resultados en una tabla.
     * Este método se encarga de leer el contenido de un archivo CSV seleccionado mediante un
     * input de tipo archivo. Luego, procesa los datos y realiza una serie de validaciones en el
     * backend usando Promises. Finalmente, muestra los registros válidos en una tabla en la interfaz.
     * @param {Event} event - El evento del input de tipo archivo que contiene el archivo CSV.
     */
    lectorCSV(event) {
      this.leyendoArchivo = true;
      this.formatoIncorrecto = false;
      this.mensajeIncorrecto = '';
      const file = event.target.files[0];
      // Verificar si el archivo tiene la extensión .csv
      if (!file || file.name.split('.').pop() !== 'csv') {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        this.leyendoArchivo = false;
        return;
      }
      
      this.validandoDocumento = true;
      this.registros = [];
      this.registrosTemporales = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ';',
        });
        if (!parsedData.data || parsedData.data.length === 0 || (parsedData.data.length === 1 && parsedData.data[0].length === 1 && parsedData.data[0][0] === '')) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        const registros = [];
        const headerRow = parsedData.data[0]; // Primera fila del archivo.
        const isHeaderRow = /^[a-zA-Z]+$/.test(headerRow[0]) && /\d/.test(headerRow[1]);
        // Inicio en 1 si es una fila de encabezados, de lo contrario empiezo en 0.
        for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
          const row = parsedData.data[index];
          if (row[0] !== '' || row[1] !== '') {
            registros.push({
              ruta: this.rutaMasiva,
              tipDoc: row[0],
              numDoc: row[1],
            });
          }
        }
        if (registros.length === 0) {
          this.formatoIncorrecto = true;
          this.mensajeIncorrecto = 'El archivo está vacío. Por favor, suba un archivo con contenido.';
          this.validandoDocumento = false;
          this.leyendoArchivo = false;
          return;
        }
        this.cantidadRegistros = registros.length;
        this.registros = registros;
        this.registrosTemporales.push([...registros]);
        this.archivoSubido = true;
        this.validaciones = Array.from({ length: this.registros.length }, () => []);
        this.registros = [];
        const backendCalls = [
          this.procesarArchivo(registros),
        ];
        Promise.all(backendCalls)
          .then(() => {
            this.$nextTick(() => {
              this.verificarValidaciones();
            });
          })
          .catch((error) => {
            this.verError(error);
            this.validandoDocumento = false;
            this.mostrarBotones = false;
            this.leyendoArchivo = false;
          });
      }; 
      reader.onerror = () => {
        this.formatoIncorrecto = true;
        this.mensajeIncorrecto = 'Error al leer el archivo. Verifique el contenido e inténtelo de nuevo.';
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      };
      reader.readAsText(file);
    },
    /**
     * Valida los registros en el archivo tomando el tipo de documento, número de documento y la ruta, 
     * para comprobar si cumplen las condiciones y poder guardar la relación afiliado-ruta en herincohan.
     * @param {Object[]} registros - La lista de registros del archivo para validar en el back-end.
     * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
     */
    procesarArchivo(registros) {
      const registrosValidos = [];

      registros.forEach((registro, index) => {
        let tipDocValido = false;
        let numDocValido = false;
        let mensajeError = "";

        if (registro.tipDoc !== undefined && registro.tipDoc !== null && registro.tipDoc !== "") {
          tipDocValido = this.listaDeTiposDocumento.some(item => item.value === registro.tipDoc.toUpperCase().trim());
        }

        if (registro.numDoc !== undefined && registro.numDoc !== null && registro.numDoc !== "") {
          const doc = registro.numDoc.trim();
          numDocValido = /\d/.test(doc) && doc.length >= 5;
        }
        if (tipDocValido && numDocValido) {registrosValidos.push({
          idEmpresa: this.enterprise.code, idRuta: registro.ruta, tipoDocumento: registro.tipDoc.toUpperCase().trim(), numeroDocumento: registro.numDoc.trim(), usuario: this.usuario });
        } else {
          registrosValidos.push({ idEmpresa: this.enterprise.code, idRuta: registro.ruta, tipoDocumento: '', numeroDocumento: '', usuario: this.usuario });
        }

        const tipo = (registro.tipDoc === undefined || registro.tipDoc === null || registro.tipDoc === "") ? true : false;
        const numero = (registro.numDoc === undefined || registro.numDoc === null || registro.numDoc === "") ? true : false;
        if (tipo && numero) {
          mensajeError = "El tipo y número de documento no pueden estar vacíos";
        } else {
          if (tipo) {
            mensajeError += "El tipo de documento no pueden estar vacío\n";
          } else if (!tipDocValido) {
            mensajeError += "Tipo de documento incorrecto\n";
          } 
          if (numero) {
            mensajeError += "El número de documento no pueden estar vacío\n";
          } else if (!numDocValido) {
            mensajeError += "El número de documento no es valido\n";
          }
        }
        if (mensajeError) {
          this.validaciones[index].push(mensajeError);
        }
      });
      
      return this.$http.post("msa-administration/api/afiliadoRuta/save-list", registrosValidos)
      .then((response) => {
        this.filasSeleccionadas = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          response.data.forEach((mensaje, index) => {
            if (mensaje.trim() !== '') {
              if (!Array.isArray(this.validaciones[index])) {
                this.validaciones[index] = [];
              }
              this.validaciones[index].push(mensaje);
            }
          });
        }
        this.verCSV = true;
        this.validandoDocumento = true;
      }).catch((error) => {
        this.verError(error);
        this.validandoDocumento = true;
        return Promise.reject(error);
      });
    },
    /**
     * Verifica las validaciones de los registros procesados.
     * Si hay errores de validación, muestra la tabla de errores.
     * Si no hay errores, cierra la vista y muesatra un mensaje exitoso con la cantidad de relaciones procesadas.
     */
    verificarValidaciones() {
      if (this.validaciones.some(val => val.length > 0)) {
        this.registros = this.registrosTemporales.flat();
        this.registrosTemporales = [];
        this.verCSV = false;
        this.tabla = true;
        this.mostrarBotones = true;
        this.validandoDocumento = false;
        this.leyendoArchivo = false;
      } else {
        this.close();
        setTimeout(() => {
          this.procesoDeCreacion = true;
        }, 200);
      }
    },
    /**
     * Descarga el resultado de las validaciones como un archivo CSV.
     */
    descargarResultadoDeValidaciones() {
      const csvContent = this.generarCSV();
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'validaciones_afiliado_ruta.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * Genera el contenido del archivo CSV a partir de los registros y sus validaciones.
     * @returns {string} - El contenido del archivo CSV.
     */
    generarCSV() {
      const eliminarTildes = (texto) => {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const limpiarMensaje = (mensaje) => {
        return mensaje.replace(/\n/g, ', ');
      };

      let csv = 'Ruta;Tipo documento;Documento;Validacion\n';

      // Descarga solo los registros con error en las validaciones 
      this.registros.forEach((registro, index) => {
        if (this.validaciones[index] && this.validaciones[index].length > 0) {
          const tipDoc = registro.tipDoc ? registro.tipDoc : '';
          const numDoc = registro.numDoc ? registro.numDoc : '';
          const validaciones = this.validaciones[index]
            .map(mensaje => limpiarMensaje(eliminarTildes(mensaje)))
            .join(', ');

          csv += `${tipDoc};${numDoc};${validaciones}\n`;
        }
      });
      return csv;
    },
    /**
     * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
     * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
     * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
     * un nuevo archivo CSV para ser procesado nuevamente.
     */
    volverACargarDocumento() {
      this.validaciones = [];
      this.registros = [];
      this.archivoSubido = false;
      this.filasSeleccionadas = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
    },
    /**
     * Restablece los valores de los campos y las variables relacionadas a la creación de una relación.
     * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
     * Reinicia el estado de edición y activación de campos.
     * Reinicia el estado del componente de observación (refs.observer).
     */
    close() {
      this.resettingForm = true;
      this.dialog = false;
      this.datosExistentesError = "";
      this.estadoRuta = "";
      this.relacionExistente = true;
      this.nuevaRelacionRuta = null;
      this.noHayResultadoInterno = false;
      this.filtroNombreAfiliado = null;
      this.filtroTipoDocumento = null;
      this.filtroNumeroDocumento = null;
      this.afiliadoSeleccionado = [];
      this.valorSeleccionado = null;
      this.seleccionAnterior = [];
      this.dialog = false;
      this.opciones = 0;
      this.$refs.archivoSubido?.reset();
      this.$refs.nuevaRelacion?.reset();
      this.validaciones = [];
      this.registros = [];
      this.archivoSubido = false;
      this.filasSeleccionadas = [];
      this.validandoDocumento = false;
      this.formatoIncorrecto = false;
      this.verCSV = true;
      this.mostrarBotones = false;
      this.tabla = false;
      this.leyendoArchivo = false;
      this.mensajeIncorrecto = '';
      this.rutaMasiva = null;
      this.estadoRutaMasiva = '';
      if (this.$refs.cargaDeRelaciones) {
        this.$refs.cargaDeRelaciones.value = '';
      }
      this.$nextTick(() => {
        this.resettingForm = false;
      });
    },
    /**
     * Muestra la validación del estado de los registros.
     * Actualiza la cantidad de registros validados y establece el progreso de validación como verdadero.
     * 
     * @param {number} cantidadRegistros - La cantidad de registros que se han validado.
     */
    mostrarValidacionEstado(cantidadRegistros) {
      this.cantidadRegistrosEstado = cantidadRegistros;
      this.validateProgress = true;
    },
    /**
     * Cierra la notificación
     */
    cerrarNotificacion() {
      this.mostrarNotificacion = false;
      this.mensajeNotificacion = "";
      this.pathNotificacion = "";
      this.tipoNotificacion = "";
    },
    /**
     * Muestra el error en una notificación correspondiente.
     * 
     * @param {*} error - Objeto de error.
     */
    verError(error) {
        
      let errorMessage = "Error desconocido, contacta con el administrador.";
      let errorPath = "";

      if (error.response && error.response.data) {
        if (error.response.data.mensaje && Array.isArray(error.response.data.mensaje)) {
          errorMessage = error.response.data.mensaje[0];
        } else if (error.response.data.error) {
          errorMessage = error.response.data.error;
        }
        if (error.response.data.path) {
          errorPath = error.response.data.path;
        }
      }
      this.pathNotificacion = errorPath;
      this.mensajeNotificacion = errorMessage;
      this.mostrarNotificacion = true;

      // Cierra la notificación después de 5 segundos
      setTimeout(() => {
        this.cerrarNotificacion();
      }, 5000);
    },
  },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
::v-deep .elevation .v-data-footer {
  width: 100%;
}
::v-deep .elevation .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .elevation .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.fondoDialog {
  background-color: #1867c0;
  color: rgb(223, 223, 223) !important;
}
.mensaje {
  text-align: center;
  padding-top: 120px !important;
}
.mensaje em {
  font-size: larger;
  color: rgb(161 161 162);
}
.mensaje:hover {
  background-color: white;
}
.icono {
  font-size: 50px;
}
.error-texto {
  color: #ff5252;
  font-size: smaller;
}
.mensaje-error {
  margin-top: 0px;
  padding: 0px 0px;
}
::v-deep .v-tabs .v-tabs-slider-wrapper {
  top: 80% !important;
}
::v-deep .tablaRelaciones div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
.header tr {
  background-color: rgb(223, 223, 223) !important;
}
.header tr th {
  font-weight: bold;
  color: black !important;
}
.archivo {
  outline: 2px dashed grey;
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 1rem;
  height: 100px;
  position: relative;
  cursor: default;
}
.archivo p {
  margin-top: .7rem;
  text-align: center;
}
.seleccionarArchivo {
  opacity: 0;
  width: 98%;
  height: 85px;
  position: absolute;
  cursor: pointer;
}
.seleccionarArchivo:disabled {
  cursor: default;
}
.error-message {
  color: red;
  white-space: pre-line;
}
.rotate-animation {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.resultadoLista {
  max-height: 12rem;
  overflow-y: auto;
  z-index: 999;
  width: 100%;
}
::v-deep .estiloFilas div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}
::v-deep .estiloFilas div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
.estiloFilas tbody tr td {
  font-size: 14px !important;
}
::v-deep .estiloFilas .v-data-footer {
  width: 100% !important;
}
::v-deep .estiloFilas .v-data-footer__select .v-select {
  margin: 5px;
  margin-left: 10px;
}
::v-deep .estiloFilas .v-data-table__wrapper {
  border: 1px solid #f7f6f6;
}
.mensaje-interno {
  text-align: center;
  padding-top: 40px !important;
}
.mensaje-interno em {
  font-size: medium;
  color: rgb(161 161 162);
}
.mensaje-interno:hover {
  background-color: white;
}
.notificationValidationEstado {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.ancho-estado {
  width: 38vw;
}
.ancho-creacion {
  width: 28vw;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.notificationValidation {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}
.notificacion {
  position: fixed;
  top: 50px;
  right: 20px;
  padding: 15px;
  border-radius: 5px;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  max-width: 30vw;
}
.notificacion span:last-child {
  cursor: pointer;
  margin-right: 10px;
  padding-top: 3px;
}
.rotate-animation-notification {
  animation: rotate-notification 1s ease-in-out; 
}
@keyframes rotate-notification  {
  0% {
    transform: rotateX(180deg);
  }

  50% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
}
.size {
  font-size: xxx-large;
  font-weight: bold;
  color: #f80505;
}
.advertencia {
  background-color: #ffffffd7 !important;
  color: #f80505;
}
::v-deep .v-text-field .v-text-field__details {
  margin: 0;
}
</style>